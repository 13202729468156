module.exports = [{
      plugin: require('/Users/wgranger/Zooniverse/touch-table-intermediate/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1224199-64","head":true,"anonymize":true},
    },{
      plugin: require('/Users/wgranger/Zooniverse/touch-table-intermediate/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,700","IBM Plex Mono:400,400italic"]}},
    },{
      plugin: require('/Users/wgranger/Zooniverse/touch-table-intermediate/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
